<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Категория целей" prop="category">
      <el-select
        v-model="scale.category"
      >
        <el-option
          v-for="template in elementCategories"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Тип цели" prop="type">
      <el-select
        v-model="scale.type"
      >
        <el-option
          v-for="template in scaleTypes"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Единица измерения" prop="unit_id">
      <el-select
        v-model="scale.unit_id"
        filterable
      >
        <el-option
          v-for="unit in scalesUnits"
          :key="unit.id"
          :label="unit.symbol"
          :value="unit.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      v-show="scale.type"
      label="Целевой показатель"
      prop="target"
    >
      <el-input
        v-if="scale.type === scaleTypesEnum.TYPE_QUALITY"
        v-model="scale.target"
        type="textarea"
        autosize
      ></el-input>

      <el-input
        v-else
        v-model="scale.target"
        type="input"
        v-number-format="{}"
      ></el-input>
    </el-form-item>

    <el-form-item label="Целевой срок достижения" prop="deadline">
      <el-date-picker
        v-model="scale.deadline"
        type="date"
        value-format="yyyy-MM-dd"
        format="dd.MM.yyyy"
      >
      </el-date-picker>
    </el-form-item>


  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import requestSender from "@/api/base/requestSender";
import {variables} from "@/componentsCompany/smz/variables";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    });

    this.$api.library.getScalesUnits()
      .then(data => {
        // У нас может быть удаленная единица. Нужно ее тоже пушнуть в массив
        if( this.scale.unit ){
          if( !data.scales_units.find(t=>t.id===this.scale.unit.id) ){
            data.scales_units.push({
              id: this.scale.unit.id,
              symbol: this.scale.unit.symbol,
            })
          }
        }

        this.scalesUnits = data.scales_units;
      })
  },

  data() {
    return {
      scalesUnits: variables.scaleTypes,
      scaleTypes: variables.scaleTypes,
      elementCategories: variables.elementCategories,
      scaleTypesEnum: variables.scaleTypesEnum,

      scale:{
        unit: null,

        type: null,
        category: null,
        unit_id: null,
        target: null,
        deadline: null,

      },

      rules: {
        type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        unit_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        target: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
      }

    }
  },

  methods: {

  }
}
</script>

<style>

</style>